<script>
import Vue from "vue";
import { Icon, Notify } from "vant";
import { DatetimePicker } from "vant";

import CommonLayout from "@/layouts/CommonLayout.vue";

Vue.use(DatetimePicker);
Vue.use(Notify);
Vue.use(Icon);
export default {
  components: {
    CommonLayout,
  },
  data() {
    return {
      date: "", //选择的日期
      title: "",
      options: [],
      currentTime: "", //时间选择
      sureTime: "", //确认的时间
      see: false, //显示时间选择框
      today: "", //当前日期
      top: 580,
      height: 580,
    };
  },
  created() {
    this.height = `${document.documentElement.clientHeight}` * 0.9;
    // console.log(this.height);
    var _this = this;
    let yy = new Date().getFullYear();
    let mm = new Date().getMonth() + 1;
    let dd = new Date().getDate();
    let hh = new Date().getHours();
    let min = new Date().getMinutes();
    if (dd < 10) {
      dd = "0" + dd;
    }
    _this.gettime = yy + "-" + mm + "-" + dd;
    //设置默认结束时间为半小时后
    this.date = _this.gettime;
    let minMs = min * 60 * 1000;
    let hhMs = hh * 60 * 60 * 1000;
    let thisMs = hhMs + minMs + 2100000;
    var hours = parseInt((thisMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = parseInt((thisMs % (1000 * 60 * 60)) / (1000 * 60));
    if (Number(hours) < 10) {
      hours = "0" + hours;
    }
    if (Number(minutes) < 10) {
      minutes = "0" + minutes;
    }
    this.sureTime = hours + ":" + minutes;
    this.today = _this.gettime;

    // let todayA = _this.gettime.replace(new RegExp("-", "gm"), "/");
    // var todayMs = Number(new Date(todayA).getTime());
    // this.sureTime='09:00'//设置默认时间为次日9：00
  },
  destroyed() {
    let input = document.getElementsByTagName("input");
    console.log("输入框集合");
    console.log(input);
    for (const item of input) {
      item.blur();
    }
  },
  methods: {
    //创建题目
    async creat() {
      const res = await this.$api.Record.create({
        name: this.title,
        ent_time: this.date + " " + this.sureTime,
        subject_option: this.options,
      });
      if (res.errorCode == 1000) {
        Notify({ type: "success", message: res.errorMessage });
        this.$router.push({
          name: "sealDisk",
        });
      } else {
        Notify({ type: "danger", message: res.errorMessage });
      }
    },
    toSealDisk() {
      this.$router.push({
        name: "sealDisk",
      });
    },
    //确认时间选择
    confirm() {
      this.see = false;
      this.sureTime = this.currentTime;
    },
    //取消时间选择
    cancel() {
      this.see = false;
    },
    //点击其他地方不显示时间选择框
    changeSee(e) {
      if (e.target.attributes["class"] != undefined) {
        if (
          e.target.attributes["class"].nodeValue != "van-picker-column" &&
          e.target.attributes["class"].nodeValue != "inpTime"
        ) {
          this.see = false;
        }
      } else {
        this.see = false;
      }
    },
  },
  watch: {
    //日期判定
    date() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let min = new Date().getMinutes();
      if (dd < 10) {
        dd = "0" + dd;
      }
      let toD = yy + "-" + mm + "-" + dd + " " + hh + ":" + min;

      if (this.date == "") {
        this.date = yy + "-" + mm + "-" + dd;
      }
      let thisA = toD.replace(new RegExp("-", "gm"), "/");
      let thisMs = new Date(thisA).getTime();
      let allTime = this.date + " " + this.sureTime;
      let allDateA = allTime.replace(new RegExp("-", "gm"), "/");
      let allDateMs = new Date(allDateA).getTime();
      let dateA = this.date.replace(new RegExp("-", "gm"), "/");
      let dateMs = new Date(dateA).getTime();
      let todayA = this.today.replace(new RegExp("-", "gm"), "/");
      var todayMs = new Date(todayA).getTime(); //得到毫秒数//7天604800000

      if (Number(dateMs) < Number(todayMs)) {
        Notify({ type: "warning", message: "所选日期不能在此之前" });
        this.date = "";
      } else if (Number(dateMs) > Number(todayMs) + 604800000) {
        Notify({ type: "warning", message: "所选日期不能超过7天" });
        this.date = "";
      } else {
        if (this.sureTime != "") {
          if (allDateMs < thisMs + 1800000) {
            Notify({
              type: "warning",
              message: "所选时间不能少于目前半个小时",
            });
            this.sureTime = "";
          }
        }
      }
    },
    //时间判定
    sureTime() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let min = new Date().getMinutes();
      if (dd < 10) {
        dd = "0" + dd;
      }
      let toD = yy + "-" + mm + "-" + dd + " " + hh + ":" + min;
      let thisA = toD.replace(new RegExp("-", "gm"), "/");
      let thisMs = new Date(thisA).getTime();
      let allTime = this.date + " " + this.sureTime;
      let allDateA = allTime.replace(new RegExp("-", "gm"), "/");
      let allDateMs = new Date(allDateA).getTime();
      if (Number(allDateMs) < Number(thisMs) + 1800000) {
        Notify({ type: "warning", message: "所选时间不能少于目前半个小时" });
        this.sureTime = "";
      }
    },
    options() {
      // console.log(this.options);
    },
  },
};
</script>

<template>
  <CommonLayout>
    <div class="box">
      <div class="title">
        <div @click="toSealDisk">查看已创建的题目</div>
      </div>
      <div>
        <div class="border">
          <div class="input" style="margin: 0">
            <span>竞猜题目：</span>
            <input
              style="height: 38px"
              type="text"
              v-model="title"
              placeholder="单行输入"
            />
          </div>
          <div class="input">
            <span>截至日期：</span>
            <input class="dateVal" v-model="date" type="Date" />
            <!-- <input style="height: 34px" v-model="date" type="time" /> -->
          </div>
          <div class="input">
            <span>截至时间：</span>
            <div
              class="inpTime"
              @click="(see = true), (currentTime = sureTime)"
            >
              {{ sureTime }}
            </div>
          </div>
          <div v-show="see" class="xzTime">
            <van-datetime-picker
              v-model="currentTime"
              type="time"
              title="选择时间"
              @confirm="confirm"
              @cancel="cancel"
            />
          </div>
        </div>
        <div id="option">
          <p>竞猜选项：</p>
          <p>
            <input v-model="options[0]" type="text" placeholder="选项A" />
          </p>
          <p>
            <input v-model="options[1]" type="text" placeholder="选项B" />
          </p>
        </div>
        <button
          v-if="
            date != '' &&
            title != '' &&
            options.length == 2 &&
            options[0] != empty &&
            options[1] != ''
          "
          class="allInput"
          @click.once="creat"
          :style="`top:${height}px`"
        >
          确认创建
        </button>
        <button
          v-else
          disabled="disabled"
          :style="`background: #d0d7e6;top:${height}px`"
        >
          确认创建
        </button>
      </div>
    </div>
  </CommonLayout>
</template>

<style lang="less" scoped>
.bgImg {
  background-image: url("../../assets/images/createBg.png");
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 -50px;
  // background: red;
}
.dateVal {
  width: 2.2rem;
  line-height: 2.2rem;
  font-size: 0.8rem;
  height: 36px;
  padding-inline-start: 0px;
  background: white;
  -webkit-appearance: button; /*控制下拉箭头*/
}
header {
  height: 40px;
  width: 100%;
  line-height: 40px;
  background: #f8f8f8;
  text-align: center;
  font-size: 16px;
  position: fixed;
  z-index: 1000;
  top: 0;
}
.xzTime {
  position: absolute;
  right: 0;
  width: 55%;
}
.border {
  margin-left: -10px;
  width: 105%;
  padding: 18px 5px 10px 10px;
  // background: white;
  box-sizing: border-box;
}
.box {
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  .title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    color: #999;
    font-size: 16px;
  }
  .input {
    width: 95%;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    align-items: center;
    margin-top: 15px;
    input {
      padding-left: 10px;
      width: 72%;
      border-radius: 5px;
      border: 1px solid #ccc;
      box-sizing: border-box;
    }
  }
  #option {
    margin-left: 10px;
    margin-top: 30px;
    p {
      text-align: left;
    }
    input {
      height: 38px;
      width: 95%;
      padding-left: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
  }

  .inpTime {
    height: 36px;
    width: 71.8%;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: white;
    font-size: 16px;
    line-height: 36px;
    text-align: left;
  }
  .add {
    height: 36px;
    width: 95%;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    line-height: 36px;
    font-size: 16px;
    color: #999;
    border: 1px dashed #ccc;
  }
  button {
    width: 82%;
    height: 42px;
    border: none;
    border-radius: 3px;
    background: #a4adb3;
    color: white;
    position: fixed;
    left: 9%;
  }
  .allInput {
    background: #e96346;
  }
}

.icon {
  font-size: 18px;
  font-weight: bold;
  position: fixed;
  top: 12px;
  left: 10px;
  z-index: 50000;
}
</style>
